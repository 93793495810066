import { createContext, useContext, useEffect, useState } from 'react'

export const CookieAttentionProvider = createContext<useCookieAttentionReturn>(null)

export const useCookieAttentionContext = () => {
  return useContext(CookieAttentionProvider)
}

type useCookieAttentionReturn = ReturnType<typeof useCookieAttention>

export const useCookieAttention = () => {
  const [displayState, setDisplayState] = useState<boolean>(false)

  const onShow = () => {
    const isAgreeCookie = localStorage.getItem('isAgreeCookie')
    if (isAgreeCookie === null) setDisplayState(true)
  }

  const onHide = () => {
    setDisplayState(false)
  }

  const addCookie = () => {
    localStorage.setItem('isAgreeCookie', '1')
  }

  return {
    displayState,
    onShow,
    onHide,
    addCookie,
  }
}
