/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError } from 'axios'
import type { DefaultOptions, UseMutationOptions, UseQueryOptions } from 'react-query'
import { QueryCache } from 'react-query'
import { QueryClient } from 'react-query'
import type { PromiseValue } from 'type-fest'

export { QueryClientProvider, useMutation, useQueries, useQuery } from 'react-query'

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
  },
}

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  queryCache: new QueryCache({
    onError: () => {
      // alert('エラー')
    },
  }),
})

export type ExtractFnReturnType<FnType extends (...args: any) => any> = PromiseValue<ReturnType<FnType>>

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>

export type MutationConfig<MutationFnType extends (...args: any) => unknown> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0]
>

export type LaravelErrorResponse = AxiosError<Record<string, string[]>, any>
export const isAxiosError = (error: any): error is LaravelErrorResponse => {
  return !!error.isAxiosError
}
