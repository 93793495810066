import { forwardRef, memo } from 'react'

import { useBreakpointValue } from '@/hooks/useUtils'

type AspectRatioProps = {
  ratio: number[] | number
} & JSX.IntrinsicElements['div']

export const AspectRatio = memo(
  forwardRef<HTMLDivElement, AspectRatioProps>(
    ({ children, ratio, className = '', ...props }, ref) => {
      const ratioValues = Array.isArray(ratio) ? ratio : [ratio]
      const computedRatio = ratioValues.map((v) => `${(1 / v) * 100}%`)
      const _ratio = useBreakpointValue<string>(computedRatio)
      return (
        <div
          className={`relative ${className}`}
          style={{
            paddingBottom: _ratio,
          }}
          ref={ref}
          {...props}
        >
          <div className="absolute top-0 left-0 w-full h-full">{children}</div>
        </div>
      )
    },
  ),
)
