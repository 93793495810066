import { memo } from 'react'

import { Button } from '@/components/UIParts/Button'
import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'
import IconMail from '~/images/Utils/ic_mail.svg'
import LogoSVG from '~/images/Utils/logo.svg'

import styles from './Header.module.scss'

export const PCHeader = memo(() => {
  return (
    <div className={`${styles['header']} flex items-center justify-between px-10 py-7`}>
      <NextLink href={pagesPath.$url()} passHref>
        <a
          aria-label="ApplyNow"
          className="inline-block w-[12.25rem] rounded-full bg-white px-6 py-3 transition-opacity duration-300 hover:opacity-75"
        >
          <LogoSVG />
        </a>
      </NextLink>

      <div className="flex items-center gap-10">
        <Nav />
        <div className="w-40 md:w-48">
          <NextLink href={pagesPath.contact.$url()} passHref>
            <a aria-label="お問い合わせ">
              <Button className="rounded-none rounded-bl-3xl md:rounded-full" data-theme="primary">
                <IconMail />
                お問い合わせ
              </Button>
            </a>
          </NextLink>
        </div>
      </div>
    </div>
  )
})

const Nav = memo(() => {
  return (
    <nav className="rounded-full bg-white px-16">
      <ul className="flex h-full gap-x-12 lg:gap-x-16">
        {NavList.map((item) => {
          return (
            <li key={item.label} className="relative">
              <NavItem {...item} />
            </li>
          )
        })}
      </ul>
    </nav>
  )
})

const NavList: NavItemProps[] = [
  {
    label: 'トップ',
    href: pagesPath.$url().pathname,
  },
  {
    label: 'サービス',
    href: pagesPath.service.$url().pathname,
    children: [
      {
        label: 'マーケティング支援',
        href: pagesPath.$url({ hash: `/service/#marketing` }).hash,
      },
      {
        label: 'クリエイティブ制作',
        href: pagesPath.$url({ hash: `/service/#creative` }).hash,
      },
    ],
  },
  {
    label: '実績',
    href: pagesPath.works.$url().pathname,
  },
  {
    label: '企業情報',
    href: "https://applynow.co.jp/company",
    target: "_blank"
  }
]

type childrenProps = {
  label: string
  href: string
}

type NavItemProps = {
  label: string
  href: string
  target?: "_blank" | "_self"
  children?: childrenProps[]
}

const NavItem = memo<NavItemProps>(({ label = '', href = '', target = "_self", children = [] }) => {
  const hasChildren = Boolean(children.length)
  if (hasChildren) {
    return (
      <div className={`${styles.header__item} group`}>
        <NextLink href={href} passHref>
          <a className={`${styles.header__link}`}>{label}</a>
        </NextLink>
        <span className={`${styles.header__icon}`} data-icon="plus"></span>
        <ul className={`${styles.submenu}`}>
          {children.map((item: childrenProps) => {
            return (
              <li key={item.label}>
                <NextLink href={item.href} passHref>
                  <a target={target} className={`${styles.submenu__link}`}>{item.label}</a>
                </NextLink>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div className={`${styles.header__item}`}>
      <NextLink href={href} passHref>
        <a target={target}className={`${styles.header__link}`} data-underlined={true}>
          {label}
        </a>
      </NextLink>
    </div>
  )
})
