import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo, useCallback, useState } from 'react'

import { Button } from '@/components/UIParts/Button'
import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'
import LogoSVG from '~/images/Utils/logo.svg'

import styles from './Header.module.scss'

export const SPHeader = memo(() => {
  const [isOpen, setMenuState] = useState<boolean>(false)

  const onClose = useCallback(() => {
    setMenuState(false)
  }, [])

  const onToggle = useCallback(() => {
    setMenuState(!isOpen)
  }, [isOpen])

  return (
    <div className={`${styles['header']}`} data-nav-show={isOpen}>
      <div className="flex items-start justify-between">
        <div className="w-28 rounded-br-3xl bg-white px-4 py-3">
          <NextLink href={pagesPath.$url()} passHref>
            <a aria-label="ApplyNow" onClick={onClose}>
              <LogoSVG />
            </a>
          </NextLink>
        </div>
        <div>
          <NavTriggerElement handleClick={onToggle} isOpen={isOpen} />
          <div className={`${styles.drawer}`}>
            <div className={`${styles.drawer__menu}`}>
              <div className={`${styles.drawer__menuInner}`}>
                <div className="mb-8">
                  <NextLink href={pagesPath.$url()}>
                    <a aria-label="ApplyNow" className="inline-block w-[9.25rem]" onClick={onClose}>
                      <LogoSVG />
                    </a>
                  </NextLink>
                </div>
                <NavElements onClose={onClose} />
                <div className="mx-auto mt-10 w-60">
                  <NextLink href={pagesPath.contact.$url()} passHref>
                    <Button as="a" data-theme="secondary" data-size="medium" onClick={onClose}>
                      <FontAwesomeIcon icon={faEnvelope} />
                      お問い合わせ
                    </Button>
                  </NextLink>
                </div>
                <div className="pb-6 pt-10">
                  <p className="text-center font-lato text-xs text-[color:#858585]">
                    ©ApplyNow inc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

type NavTriggerElementProps = {
  isOpen: boolean
  handleClick: () => void
}

const NavTriggerElement = memo<NavTriggerElementProps>(({ isOpen, handleClick }) => {
  return (
    <button
      aria-label="menu"
      className={`${styles.hamburger}`}
      onClick={() => {
        handleClick()
      }}
    >
      <span className={`${styles.hamburger__text}`}>{!isOpen ? 'メニュー' : 'とじる'}</span>
      <div>
        <span className={`${styles.hamburger__line}`}></span>
        <span className={`${styles.hamburger__line}`}></span>
        <span className={`${styles.hamburger__line}`}></span>
      </div>
    </button>
  )
})

type NavElementsProps = {
  onClose: () => void
}
const NavElements = memo<NavElementsProps>(({ onClose }) => {
  return (
    <nav>
      <ul className="grid gap-y-10">
        {NavList.map((item, index) => {
          const hasChildren = Boolean(item.children.length)
          return (
            <li key={index}>
              <NextLink href={item.href} passHref>
                <a
                  className="font-bold tracking-wider before:mr-1 before:content-['-']"
                  target={item.target ?? "_self"}
                  onClick={onClose}
                >
                  <span>{item.label}</span>
                </a>
              </NextLink>
              {hasChildren && (
                <ul className="mt-7 pl-4">
                  {item.children.map((child) => (
                    <li key={child.label} className="mb-6 last:mb-0">
                      <NextLink href={child.href}>
                        <a className="text-sm font-bold text-bg-blue" onClick={onClose}>
                          {child.label}
                        </a>
                      </NextLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
})

const NavList = [
  {
    label: 'トップ',
    href: pagesPath.$url(),
    children: [],
  },
  {
    label: 'サービス',
    href: pagesPath.service.$url(),
    children: [
      {
        label: 'マーケティング支援',
        href: pagesPath.$url({ hash: `/service/#marketing` }).hash,
      },
      {
        label: 'クリエイティブ制作',
        href: pagesPath.$url({ hash: `/service/#creative` }).hash,
      },
    ],
  },
  {
    label: '実績',
    href: pagesPath.works.$url(),
    children: [],
  },
  {
    label: '企業情報',
    href: "https://applynow.co.jp/company",
    children: [],
    target: "_blank"
  }
]
