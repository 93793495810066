import type { PropsWithChildren } from 'react'
import { memo } from 'react'

import { useInterSection } from '@/hooks/useInterSection'

type Props = PropsWithChildren<JSX.IntrinsicElements['div']>

export const FadeContent = memo<Props>(({ children, className = '', ...props }) => {
  const { ref, isInterSection } = useInterSection()

  return (
    <div
      ref={ref}
      className={`opacity-0 ${isInterSection ? 'animate-fadeIn' : ''} ${className}`}
      {...props}
    >
      {children}
    </div>
  )
})
