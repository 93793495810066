export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "contact": {
    $url: (url?: { hash?: string }) => ({ pathname: '/contact' as const, hash: url?.hash })
  },
  "news": {
    _news_id: (news_id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/news/[news_id]' as const, query: { news_id }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/news' as const, hash: url?.hash })
  },
  "service": {
    $url: (url?: { hash?: string }) => ({ pathname: '/service' as const, hash: url?.hash })
  },
  "works": {
    _work_id: (work_id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/works/[work_id]' as const, query: { work_id }, hash: url?.hash })
    }),
    "page": {
      _page: (page: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/works/page/[page]' as const, query: { page }, hash: url?.hash })
      })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/works' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
