import type { MutableRefObject } from 'react'
import { useEffect, useRef, useState } from 'react'

type UseInterSectionProps = Omit<IntersectionObserverInit, 'root'> & {
  once?: boolean
}
type UseInterSectionReturn<T> = {
  ref: MutableRefObject<T>
  isInterSection: boolean
}

export const useInterSection: <T = HTMLDivElement>(
  P?: UseInterSectionProps,
) => UseInterSectionReturn<T> = ({ once = false, ...config } = {}) => {
  const ref = useRef(null)
  const observeRef = useRef<IntersectionObserver>(null)
  const [isInterSection, setState] = useState(false)

  useEffect(() => {
    const observerConfig: IntersectionObserverInit = {
      rootMargin: '0px 0px -10% 0px',
      threshold: 0.1,
      ...config,
    }

    const callBack: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onChangeState(true)
        }
      })
    }

    const onChangeState = (value: boolean) => {
      setState(value)
      if (once && value) {
        observeRef.current.unobserve(ref.current)
      }
    }

    observeRef.current = new IntersectionObserver(callBack, observerConfig)
    observeRef.current.observe(ref.current)
  }, [config, once])

  return {
    ref,
    isInterSection,
  }
}
