import { forwardRef, memo } from 'react'

import HeadingAttendImage from '~/images/Utils/heading--attend.svg'

type Prop = {
  main: string
  sub?: string
  as?: 'h1' | 'h2' | 'h3' | 'h4'
} & JSX.IntrinsicElements['h2']

const HeadingInner = forwardRef<HTMLDivElement, Prop>(
  ({ main, sub, className = '', as: CustomTag = 'h2', ...props }, ref) => {
    return (
      <div
        className={`grid place-content-center justify-items-center gap-2 ${className}`}
        ref={ref}
        {...props}
      >
        <div className="w-8 text-main md:w-10">
          <HeadingAttendImage />
        </div>
        <CustomTag className="text-center font-lato text-4xl md:text-6xl">{main}</CustomTag>
        {sub && <span className="font-bold">{sub}</span>}
      </div>
    )
  },
)

export const Heading = memo(HeadingInner)
