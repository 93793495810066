import type { GetLayout } from '_types/next'
import { useMemo } from 'react'

import { Footer } from '@/components/Domains/Footer'
import { Header } from '@/components/Domains/Header'
import { Topic } from '@/components/Templates/Home/parts/Topic'

export const DefaultLayout: GetLayout = (page) => {
  const isDisplayTopics = useMemo(() => {
    return page.props.isDisplayTopics ?? true
  }, [page.props.isDisplayTopics])

  return (
    <div className="grid min-h-screen auto-rows-[auto_1fr_auto]">
      <Header />

      <main>{page}</main>

      {isDisplayTopics && <Topic />}
      <div>
        <Footer />
      </div>
    </div>
  )
}
