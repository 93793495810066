import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { memo, useEffect, useState } from 'react'

import { AspectRatio } from '@/components/UIParts/AspectRatio'
import { FadeContent } from '@/components/UIParts/FadeContent'
import { Heading } from '@/components/UIParts/Heading/index'
import { NextImage } from '@/lib/next'
import TopicPeople01 from '~/images/Topic/people01.png'
import TopicPeople02 from '~/images/Topic/people02.png'
import BgWaveImage from '~/images/Utils/bg_wave--gray.png'

type TopicCollectionProps = {
  items?: ArticlesProps[]
}

export const Topic = memo<TopicCollectionProps>(() => {
  const [articles, setArticles] = useState([])
  // react-query使おう
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/api/note/')
      const data = await response.json()
      setArticles(data)
    }

    fetchData()
  }, [])

  return (
    <section className="relative bg-bg-gray pb-24 pt-10 md:px-0 md:pb-[7.5rem] md:pt-0">
      <div
        style={{
          backgroundImage: `url(${BgWaveImage.src})`,
          backgroundSize: `cover`,
          backgroundRepeat: 'no-repeat',
        }}
        className="absolute -top-8 left-0 h-9 w-full md:-top-32 md:h-[8.25rem]"
      />
      <div className="container mx-auto">
        <Heading main="Topic" sub="トピック" className="mb-10" />

        <FadeContent className="relative">
          <div className="hidden md:absolute md:-top-28 md:left-32 md:block md:w-[13rem]">
            <NextImage
              src={TopicPeople01}
              width={TopicPeople01.width}
              height={TopicPeople01.height}
              alt=""
            />
          </div>
          <div className="mb-5 text-center text-sm font-bold md:text-base">
            個性豊かなメンバーで、
            <br />
            クリエイティブやWEBに関する情報を発信中！
          </div>
          <div className="hidden md:absolute md:-top-28 md:right-24 md:block md:w-[15.375rem]">
            <NextImage
              src={TopicPeople02}
              width={TopicPeople02.width}
              height={TopicPeople02.height}
              alt=""
            />
          </div>
        </FadeContent>

        <FadeContent className="relative bg-white p-6 pb-[4.5rem] md:px-20 md:py-16">
          <div className="mb-7 text-center font-bold md:mb-5 md:text-left md:text-lg">記事一覧</div>
          <TopicCollection items={articles} />
          <div className="text-center">
            <a
              href="https://note.com/applynow/"
              target="_blank"
              rel="noopener noreferrer"
              className="border-b border-bg-blue text-lg font-bold text-bg-blue md:absolute md:right-20 md:top-16"
            >
              <span className="pr-4">もっとみる</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </a>
          </div>
        </FadeContent>
      </div>
      <div className="absolute -bottom-32 flex gap-5 md:-bottom-28 md:hidden md:w-[21.625rem]">
        <NextImage
          src={TopicPeople01}
          width={TopicPeople01.width}
          height={TopicPeople01.height}
          alt=""
        />
        <NextImage
          src={TopicPeople02}
          width={TopicPeople02.width}
          height={TopicPeople02.height}
          alt=""
        />
      </div>
    </section>
  )
})

const TopicCollection = memo<TopicCollectionProps>(({ items }) => {
  return (
    <div className="mb-10 grid gap-x-8 gap-y-12 md:mb-0 md:grid-cols-3">
      {items.map((article) => {
        return <TopicItem {...article} key={article.id} />
      })}
    </div>
  )
})

type ArticlesProps = {
  id: string
  title: string
  thumbnail: string
  publishAt: string
  link: string
}

const TopicItem = memo<ArticlesProps>(({ ...article }) => {
  const formatDate = dayjs(article.publishAt).format('YYYY.M.DD')
  return (
    <FadeContent>
      <a href={article.link} target="_blank" rel="noopener noreferrer" className="article">
        <AspectRatio ratio={128 / 67} className="article-image">
          <img src={article.thumbnail} alt={article.title} />
        </AspectRatio>
        <div className="article-date">{formatDate}</div>
        <div className="article-title">{article.title}</div>
      </a>
    </FadeContent>
  )
})
