import { memo } from 'react'

import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'

import { useCookieAttentionContext } from '../hooks/useCookieAttention'

export const Cookie = memo(() => {
  const { displayState, onHide, addCookie } = useCookieAttentionContext()

  const onAgree = () => {
    addCookie()
    onHide()
  }

  if (!displayState) return null

  return (
    <div className="fixed bottom-0 left-0 z-50 w-full py-4 md:py-5 bg-black bg-opacity-70 animate-fadeInDown">
      <div className="container mx-auto">
        <div className="grid md:flex justify-between items-center gap-6 px-6 md:px-0">
          <p className="text-white leading-loose text-13">
            当サイトはクッキー(Cookie)を使用しています。
            <br />
            サイトのクッキー(Cookie)の使用に関しては、「<a href="https://applynow.co.jp/privacy" target='_blank' className="underline" rel="noopener noreferrer">プライバシーポリシー</a>」をお読みください。
          </p>
          <div className="justify-self-center">
            <button
              className="rounded-full text-sm border-2 border-white text-white w-20 py-1 font-bold"
              onClick={onAgree}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})
