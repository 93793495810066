import type { PropsWithChildren } from 'react'
import { forwardRef, memo } from 'react'

import styles from './Button.module.scss'

type ButtonProps = PropsWithChildren<{
  theme?: 'primary' | 'secondary'
  size?: 'small' | 'medium' | 'base'
  as?: 'a' | 'button'
  type?: 'button' | 'submit' | 'reset'
  className?: string
  disabled?: boolean
  onClick?: () => void
}>

export const Button = memo(
  forwardRef<HTMLAnchorElement & HTMLButtonElement, ButtonProps>(
    (
      {
        children,
        as: CustomTag = 'button',
        theme = 'primary',
        size = 'base',
        className = '',
        ...props
      },
      ref,
    ) => {
      return (
        <CustomTag
          className={`button rounded-full ${styles.button} ${className}`}
          type="button"
          data-size={size}
          data-theme={theme}
          ref={ref}
          {...props}
        >
          {children}
        </CustomTag>
      )
    },
  ),
)
