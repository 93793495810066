import { useEffect, useState } from 'react'

export const removeHTML = (str = '') => {
  return str.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
}

export const unEscapeHTML = (str = '') => {
  const patterns = {
    '&lt;': '<',
    '&gt;': '>',
    '&amp;': '&',
    '&quot;': '"',
    '&#x27;': "'",
    '&#x60;': '`',
  }

  return str.replace(/&(lt|gt|amp|quot|#x27|#x60);/g, (match) => {
    return patterns[match]
  })
}

export const getFullPath = (url = '') => {
  return `${process.env.NEXT_PUBLIC_ASSETPREFIX ?? ''}${url}`
}

export const replaceBrText = (str = '') => {
  return str.replace(/\r?\n/g, '<br/>')
}

export const rem = (value: number) => {
  return `${value / 16}rem`
}

type AnkerMoveProps = {
  el: HTMLElement
  offset: number
}
export const ankerMove = ({ el, offset = 0 }: AnkerMoveProps) => {
  const targetRect = el.getBoundingClientRect()

  window.scrollTo({
    top: targetRect.top - offset,
    behavior: 'smooth',
  })
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
  })
}

type TruncateTextProps = {
  text: string
}

export const TruncateText = ({ text }: TruncateTextProps) => {
  const maxLength = 30
  if (text.length <= maxLength) {
    return <>{text}</>
  }
  const truncatedText = text.slice(0, maxLength - 3) + '...'
  return <>{truncatedText}</>
}

export const useBreakpointValue = <TValue,>(values: TValue[]): TValue => {
  const fallbackValue = values[0]
  const breakpoints = ['(max-width:767px)', '(min-width:767px)']

  const [currentValue, setValue] = useState<TValue>()

  useEffect(() => {
    const computedValue = () => {
      const value = values
        .map((v, index) => {
          const query = breakpoints[index]
          if (query === undefined) return undefined
          return window.matchMedia(query).matches ? v : undefined
        })
        .find((v) => typeof v !== 'undefined')
      if (value) setValue(value)
    }
    computedValue()
    window.addEventListener('resize', computedValue)
    return () => {
      window.removeEventListener('resize', computedValue)
    }
  }, [values])

  return currentValue || fallbackValue
}
