import { memo } from 'react'

import { PCHeader } from './_pc'
import { SPHeader } from './_sp'

export const Header = memo(() => {
  return (
    <header className={`fixed top-0 z-[1000] w-full transition-all duration-500`}>
      <div className="block md:hidden">
        <SPHeader />
      </div>
      <div className="hidden md:block">
        <PCHeader />
      </div>
    </header>
  )
})
