import '@/assets/styles/globals.scss'

import type { AppPropsWithLayout } from '_types/next'
import { useEffect } from 'react'

import { Cookie } from '@/components/Domains/Cookie/components/CookieAttention'
import {
  CookieAttentionProvider,
  useCookieAttention,
} from '@/components/Domains/Cookie/hooks/useCookieAttention'
import { MetaRender } from '@/hooks/useMeta'
import { DefaultLayout } from '@/layouts/Default'
import { queryClient, QueryClientProvider } from '@/lib/react-query'

type PageProps = any
export const App = ({ Component, pageProps }: AppPropsWithLayout<PageProps>) => {
  const getLayout = Component.getLayout ?? DefaultLayout
  const cookieAttention = useCookieAttention()

  useEffect(() => {
    const isDisplayCookieAttention = pageProps.autoDisplayCookieAttention ?? true
    if (isDisplayCookieAttention) cookieAttention.onShow()
  }, [cookieAttention, pageProps.autoDisplayCookieAttention])

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <MetaRender {...pageProps.meta} />
        <CookieAttentionProvider.Provider value={cookieAttention}>
          {getLayout(<Component {...pageProps} />)}
          <Cookie />
        </CookieAttentionProvider.Provider>
      </QueryClientProvider>
    </div>
  )
}

export default App
