import Head from 'next/head'

import { getFullPath, removeHTML } from './useUtils'

export type MetaFactoryProps = {
  title?: string
  description?: string
  ogDescription?: string
  ogType?: 'website' | 'article'
  ogImage?: string
  noindex?: boolean
}

export type MetaFactoryReturn = ReturnType<typeof metaFactory>
export const metaFactory = ({
  title,
  description,
  ogDescription,
  ogType = 'website',
  ogImage,
  noindex = false,
}: MetaFactoryProps) => {
  return {
    title: title ? `${removeHTML(title)} | 株式会社ApplyNow` : '株式会社ApplyNow',
    description: description
      ? removeHTML(description)
      : '株式会社ApplyNow（アプライナウ）のデジタルマーケティング事業では、WEBマーケティングの戦略立案からクリエイティブ制作、運用代行まで一気通貫でサポートします。',
    ogDescription: ogDescription
      ? removeHTML(ogDescription)
      : '株式会社ApplyNow（アプライナウ）のデジタルマーケティング事業では、WEBマーケティングの戦略立案からクリエイティブ制作、運用代行まで一気通貫でサポートします。',
    ogType,
    ogImage: ogImage ?? getFullPath('/ogp.png'),
    noindex,
  }
}

export const MetaRender = (props: MetaFactoryReturn) => {
  const { noindex, description, ogDescription, ogImage, title } = props
  return (
    <Head>
      {noindex && <meta name="robots" content="noindex" />}

      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ja_JP" />
      <meta name="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={title} />

      <title>{title}</title>
    </Head>
  )
}
