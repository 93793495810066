import { faAngleUp, faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { MutableRefObject } from 'react'
import { memo, useCallback } from 'react'
import { animateScroll } from 'react-scroll'

import { Button } from '@/components/UIParts/Button'
import { pagesPath } from '@/lib/$path'
import { NextLink } from '@/lib/next'
import HeadingAttendImage from '~/images/Utils/heading--attend.svg'
import LogoImage from '~/images/Utils/logo_AN.svg'

type FooterProps = {
  ref?: MutableRefObject<HTMLDivElement>
}

export const Footer = memo<FooterProps>(({ ref }) => {
  return (
    <>
      <footer id="footer">
        <div className="relative z-30 bg-white" ref={ref}>
          <div className="mx-auto flex flex-col items-center justify-center gap-x-8 px-6 pb-16 pt-10 md:flex-row md:px-0 md:py-32">
            <div className="inline-grid grid-cols-[max-content_minmax(0,1fr)_max_content] items-center gap-x-2 ">
              <div
                className="grid items-center gap-2 text-center md:grid-cols-[max-content_minmax(0,1fr)] md:gap-0 md:text-left"
                aria-label="お問い合わせ"
              >
                <div className="mx-auto w-10 text-main">
                  <HeadingAttendImage />
                </div>
                <h2 className="font-lato text-4xl md:text-6xl">Contact</h2>
                <span />
                <span className="text-sm font-bold md:text-base">お問い合わせ</span>
              </div>
            </div>

            <div className="mb-10 md:mb-0">
              <p className="mb-4 text-center text-base font-bold md:text-left">
                Webサイト制作・マーケティング支援の
                <br className="block md:hidden" />
                ご相談など
                <br className="hidden md:block" />
                お気軽にご連絡ください。
              </p>
              <p className="text-center text-xs md:text-left">
                ※ご返信までに3～5営業日程度いただいております。
                <br className="block md:hidden" />
                ※休業日：土日祝
              </p>
            </div>
            <div>
              <NextLink href={pagesPath.contact.$url()} passHref>
                <Button as="a" data-size="medium" data-theme="secondary">
                  <FontAwesomeIcon icon={faEnvelope} />
                  お問い合わせ
                </Button>
              </NextLink>
            </div>
          </div>

          <div className="relative border-t-bg-blue md:border-t-2">
            <div className="z-10 transition-all duration-300 md:absolute md:bottom-[calc(100%-2.3rem)] md:right-10 md:hover:bottom-full">
              <PageTop />
            </div>
            <div className="relative z-20 bg-white pt-16 pb-10">
              <div className="container mx-auto">
                <div className="mb-6 flex justify-center md:mb-10">
                  <LogoImage aria-label="ApplyNow" />
                </div>
                <div className="text-center">
                  <a href="https://applynow.co.jp/privacy" target='_blank' className="inline-flex items-center gap-x-1 text-13 transition-opacity md:hover:opacity-50" rel="noopener noreferrer">
                    <span>プライバシーポリシー</span>
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="text-bg-blue" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="py-6 md:pt-0">
            <p className="text-center font-lato text-xs text-attend">©2023 ApplyNow, Inc.</p>
          </div>
        </div>
      </footer>
    </>
  )
})

const PageTop = memo(() => {
  const onClick = useCallback(() => {
    animateScroll.scrollToTop()
  }, [])

  return (
    <button
      type="button"
      className="w-full bg-bg-blue py-2 text-white md:w-20 md:rounded-t-3xl md:py-6 md:pb-4"
      onClick={() => onClick()}
    >
      <div className="grid place-content-center justify-items-center md:gap-2">
        <FontAwesomeIcon icon={faAngleUp} className="text-2xl" />
        <span>Top</span>
      </div>
    </button>
  )
})
